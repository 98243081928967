import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

function Themes(props: any) {
  return (
    <>
      <h1>All Themes</h1>

      <Container>
        <Row className="justify-content-lg-center">
          {props.themesData.map((x: any, index: number) => (
            <Col md="auto" lg={4}>
              <Button
                variant="primary"
                key={index}
                className="d-flex justify-content-between m-lg-1"
              >
                {/* <a >{x.name}</a> */}
                <a
                  href={x.reference}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none flex-wrap text-light"
                >
                  {x.name}
                </a>
              </Button>
            </Col>
          ))}
        </Row>
      </Container>
      {/* <Button variant="link">Link</Button> */}
    </>
  );
}

export default Themes;
