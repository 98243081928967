import React from 'react'
import Themes from '../components/Themes.tsx'
import data from "../utils/Themes.json"

function ThemesPage() {
  return (
    <div>
        <Themes themesData={data}/>
    </div>
  )
}

export default ThemesPage